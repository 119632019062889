import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const CataloguePricePlansState = (state: RootState) => state.clients.cataloguePricePlans;

export const selectCataloguePricePlans = (state: RootState) => CataloguePricePlansState(state).rows;
export const selectCataloguePricePlansLoading = (state: RootState) => CataloguePricePlansState(state).loading;

export const selectCataloguePricePlanById = createSelector(
  [selectCataloguePricePlans, (_: RootState, id?: string) => id],
  (rows, id) => rows.find(({ id: rowId }) => id === rowId),
);
