import { apiClientSupportPlans } from '~constants/api';

import { get } from '~libs/genericAction';
import { createThunks } from '~libs/reduxUtils';

export const typePrefix = 'client-support-plans';

const config = [{ actionType: 'getAll', apiPath: apiClientSupportPlans, method: get }];

export const { getAll } = createThunks(typePrefix, config);
