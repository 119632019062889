import { derivePricePlan, deriveSupportPlan } from '~clients/lib/derive';
import { validatePricePlan, validateSupportPlan } from '~clients/lib/validate';

import CascadingSelectors from '~components/FormFields/Inputs/CascadingSelectors';
import { Step } from '~components/WizardForm/props';
import FormInputDate from '~appointments/components/FormInputDate';

import { getAll as getPricePlanRevisions } from '~clients/actions/cataloguePricePlanRevisions';
import { getAll as getPricePlans } from '~clients/actions/cataloguePricePlans';
import { getAll as getSupportPlanRevisions } from '~clients/actions/clientSupportPlanRevisions';
import { getAll as getSupportPlans } from '~clients/actions/clientSupportPlans';
import { getAll as getClients } from '~main/actions/clients';

import { clear as clearPricePlans } from '~clients/reducers/cataloguePricePlanRevisions';
import { clear as clearSupportPlans } from '~clients/reducers/clientSupportPlanRevisions';

import { selectCataloguePricePlans } from '~clients/selectors/cataloguePricePlans';
import { selectCataloguePricePlanRevisions } from '~clients/selectors/cataloguePricePlansRevisions';
import { selectClientSupportPlanRevisions } from '~clients/selectors/clientSupportPlanRevisions';
import { selectClientSupportPlans } from '~clients/selectors/clientSupportPlans';
import { selectLookupClients } from '~main/selectors/clients/clients';

const newBudgetFields: Step[] = [
  {
    step: 1,
    title: 'Create new budget',
    fields: {
      id: {
        type: 'hidden',
      },
      client_name: {
        type: 'select',
        field: 'client_id',
        caption: 'Client',
        className: 'text-left',
        required: true,
        colSize: 4,
        order: 100,
        selectorOptions: selectLookupClients,
        selectorActions: getClients,
      },
      price_plan: {
        type: 'custom',
        caption: 'Price Plan',
        component: CascadingSelectors,
        cascadingSelectors: [
          {
            position: 1,
            id: 'price_plan_id',
            name: 'price_plan_id',
            selectorActions: getPricePlans,
            selectorOptions: selectCataloguePricePlans,
            fKey: 'plan_id',
          },
          {
            position: 2,
            id: 'price_plan_revision_id',
            name: 'price_plan_revision_id',
            selectorActions: getPricePlanRevisions,
            selectorOptions: selectCataloguePricePlanRevisions,
            reducerFunctions: { clear: clearPricePlans },
            label: 'Revision',
          },
        ],
        deriveValue: derivePricePlan,
        validate: validatePricePlan,
        colSize: 4,
        order: 200,
      },
      support_plan: {
        type: 'custom',
        caption: 'Support Plan',
        component: CascadingSelectors,
        cascadingSelectors: [
          {
            position: 1,
            id: 'support_plan_id',
            name: 'support_plan_id',
            selectorActions: getSupportPlans,
            selectorOptions: selectClientSupportPlans,
            fKey: 'plan_id',
          },
          {
            position: 2,
            id: 'support_plan_revision_id',
            name: 'support_plan_revision_id',
            selectorActions: getSupportPlanRevisions,
            selectorOptions: selectClientSupportPlanRevisions,
            reducerFunctions: { clear: clearSupportPlans },
            label: 'Revision',
          },
        ],
        deriveValue: deriveSupportPlan,
        validate: validateSupportPlan,
        colSize: 4,
        order: 300,
      },
      start_date: {
        type: 'custom',
        component: FormInputDate,
        caption: 'Start Date',
        className: 'text-left',
        required: true,
        colSize: 4,
        order: 400,
      },
      end_date: {
        type: 'custom',
        component: FormInputDate,
        caption: 'End Date',
        className: 'text-left',
        required: true,
        colSize: 4,
        order: 500,
      },
      sah_level: {
        type: 'number',
        field: 'sah_level',
        caption: 'SAH Level',
        className: 'text-left',
        required: true,
        colSize: 4,
        order: 600,
      },
    },
  },
  {
    step: 2,
    title: 'Sections & Items',
    fields: {
      // TO BE MASSIVELY FLESHED OUT LATER -> REQUIRES CUSTOM COMPONENTS => TAV-749
      sections: {
        type: 'text',
        field: 'sections',
        caption: 'Sections',
        className: 'text-left',
        required: true,
        colSize: 6,
        order: 100,
      },
      items: {
        type: 'text',
        field: 'items',
        caption: 'Items',
        className: 'text-left',
        required: true,
        colSize: 6,
        order: 200,
      },
    },
  },
  {
    step: 3,
    title: 'Review',
    fields: {
      warning: {
        type: 'alert-warning',
        className: 'fw-bold w-100',
        colSize: 12,
        order: 100,
        // TODO
        defaultValue: 'INSERT REVIEW COMPONENT HERE',
      },
    },
  },
];

export default newBudgetFields;
