import { buildSlice, CommonState, Row } from '~libs/reduxUtils';

import { getRunningLate, typePrefix } from '~weekly-planner/actions/users';

interface State extends CommonState<Row> {
  runningLate: {
    appointmentsNow: Row[];
    appointmentsBeforeNow: Row[];
    notLoggedIn: Row[];
  };
}

const initialState: State = {
  loading: 'idle',
  orderBy: {
    availability: false,
    distance: false,
  },
  runningLate: {
    appointmentsBeforeNow: [],
    appointmentsNow: [],
    notLoggedIn: [],
  },
  error: undefined,
  rows: [],
};

const slice = buildSlice<State>(typePrefix, initialState, {
  extraReducers: (builder) => {
    builder.addCase(getRunningLate.fulfilled, (state, action) => {
      state.loading = 'fulfilled';

      const {
        not_logged_in: notLoggedIn = [],
        appointments_before_now: appointmentsBeforeNow = [],
        appointments_now: appointmentsNow = [],
      } = action.payload.data || {}; // Default to an empty object if action.payload.data is undefined

      state.runningLate = { notLoggedIn, appointmentsBeforeNow, appointmentsNow };
    });
  },
});

export default slice.reducer;
