import axios from 'axios';

import { buildSlice, CommonState } from '~libs/reduxUtils';

import { getAll, typePrefix } from '~clients/actions/cataloguePricePlanRevisions';

interface CataloguePricePlanRevision {
  id: string;
  plan_id: string;
  sequence: number;
  name: string;
}

const initialState: CommonState<CataloguePricePlanRevision> = {
  loading: 'idle',
  hasLoaded: false,
  error: null,
  rows: [],
};

const slice = buildSlice<CommonState<CataloguePricePlanRevision>, CataloguePricePlanRevision>(
  typePrefix,
  initialState,
  {
    commonConfig: { getAll },
  },
);

/**
 * TEMPORARY - remove after API integration
 */
const stub: CataloguePricePlanRevision[] = [
  {
    id: 'ee8936d8-fbb1-400a-b21c-d969f8fb22d1',
    plan_id: '637549d3-bbaf-47e3-a03b-023792ebaad4',
    sequence: 1,
    name: 'Plan 1 - Revision 1',
  },
  {
    id: '40976c93-4c63-46a0-90e8-6bd0b7c0b612',
    plan_id: '637549d3-bbaf-47e3-a03b-023792ebaad4',
    sequence: 2,
    name: 'Plan 1 - Revision 2',
  },
  {
    id: 'c515caed-9991-4726-9ded-d680c369b7c0',
    plan_id: '2b25c5d9-44ae-4895-b119-06386ffa4856',
    sequence: 1,
    name: 'Plan 2 - Revision 1',
  },
  {
    id: 'c6ea589c-6520-478f-b23f-a7d6f9e2d4e1',
    plan_id: '2b25c5d9-44ae-4895-b119-06386ffa4856',
    sequence: 2,
    name: 'Plan 2 - Revision 2',
  },
  {
    id: '4a086ffc-520e-452d-9395-a30f89f67610',
    plan_id: 'd903fdc9-a05e-4a55-ad32-51306662cd4c',
    sequence: 1,
    name: 'Plan 3 - Revision 1',
  },
  {
    id: '77ceb51b-78e8-4fe6-b0c7-8b210c4d9f9a',
    plan_id: 'd903fdc9-a05e-4a55-ad32-51306662cd4c',
    sequence: 2,
    name: 'Plan 3 - Revision 2',
  },
];

axios.interceptors.response.use((response) => {
  if (response.config.url && response.config.url.match(/\/catalogues\/price-plans\/.+\/revisions(\/|$)/)) {
    const planId = response.config.url.split('/').slice(-2, -1)[0];
    const filteredStub = stub.filter((item) => item.plan_id === planId);
    response.data = { success: true, message: `Filtered price plan revisions for ${planId}`, data: filteredStub };
  }

  return response;
});

// default actions
export const { clear, updateParams } = slice.actions;
export default slice.reducer;
