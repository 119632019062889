import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Appointments from '~appointments/Appointments';
import Appointment from '~appointments/pages/Appointment';
import AppointmentCancellation from '~appointments/pages/Cancellation';
import RepeatAppointments from '~appointments/RepeatAppointments';
import User from '~care-workers/User';
import Users from '~care-workers/Users';
import Dashboard from '~dashboard/Dashboard';
import AppDynamicRoute from '~main/AppDynamicRoute';
import Home from '~main/Home';
import Login from '~main/Login';
import Logout from '~main/Logout';
import PrivateRoute from '~main/PrivateRoute';
import WeeklyPlanner from '~weekly-planner/WeeklyPlanner';

import PageNotFound from '~components/PageNotFound';
import RedirectTo from '~components/RedirectTo';
import { Budgets, NewBudget } from '~clients/components';

import SessionCheck from './SessionCheck';

function AppRoutes() {
  const router = createBrowserRouter([
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/logout',
      element: <Logout />,
    },
    {
      path: '/auth-session',
      element: <SessionCheck />,
    },
    {
      element: <PrivateRoute />,
      children: [
        {
          path: '/',
          element: <Home />,
          children: [
            // dashboard
            { path: 'home', element: <Dashboard /> },

            // appointments
            { path: 'appointments', element: <Appointments key={1} /> },
            { path: 'appointments/repeats', element: <RepeatAppointments key={3} /> },
            { path: 'appointments/new', element: <Appointment key={2} isNew={true} /> },
            { path: 'appointments/repeats/new', element: <Appointment key={4} isNew={true} forceRepeat={true} /> },
            { path: 'appointments/transport/new', element: <Appointment key={5} isNew={true} isTransport={true} /> },
            { path: 'appointments/:id', element: <AppDynamicRoute Route={Appointment} paramKey={'id'} /> },
            { path: 'appointments/:id/cancel', element: <AppointmentCancellation /> },

            // users
            { path: 'users', element: <Users /> },
            { path: 'users/:id', element: <AppDynamicRoute Route={User} paramKey={'id'} /> },

            // clients / budgets
            { path: 'clients/budgets', element: <Budgets /> },
            { path: 'clients/budgets/new', element: <NewBudget /> },

            // weekly planner
            { path: 'weekly-planner', element: <WeeklyPlanner /> },

            { path: 'redirect/*', element: <RedirectTo /> },
            {
              path: '*', // This will catch all unmatched routes
              element: <PageNotFound />,
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default AppRoutes;
