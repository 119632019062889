import { RootState } from 'store';

const AwardAlertsState = (state: RootState) => state.weeklyPlanner.awardAlerts;

export const selectAll = (state: RootState) => AwardAlertsState(state).rows;
export const selectLoading = (state: RootState) => AwardAlertsState(state).loading;
export const selectHasLoaded = (state: RootState) => AwardAlertsState(state).hasLoaded;
export const selectParams = (state: RootState) => AwardAlertsState(state).params;
export const selectAwardAlertTypes = (state: RootState) => AwardAlertsState(state).awardAlertTypes;
export const selectAwardAlertsVisibilities = (state: RootState) => AwardAlertsState(state).awardAlertsVisibilities;
export const selectAllAwards = (state: RootState) => AwardAlertsState(state).rows;
export const selectAwardCount = (state: RootState) => AwardAlertsState(state).count;
