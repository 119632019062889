import { combineReducers } from '@reduxjs/toolkit';

import * as appointmentsReducers from './appointments';
import runningLate from './appointments/runningLate';
import awardAlerts from './awardAlerts';
import * as clientsReducers from './clients';
import unsaved from './unsaved';
import * as usersReducers from './users';
import weeklyPlannerReducer from './weeklyPlanner';

export const weeklyPlanner = {
  ...appointmentsReducers,
  ...clientsReducers,
  ...usersReducers,
  weeklyPlanner: weeklyPlannerReducer,
  awardAlerts,
  runningLate,
  unsaved,
};

export default combineReducers(weeklyPlanner);
