import { combineReducers } from '@reduxjs/toolkit';

import budgets from './budgets';
import cataloguePricePlanRevisions from './cataloguePricePlanRevisions';
import cataloguePricePlans from './cataloguePricePlans';
import clientSupportPlanRevisions from './clientSupportPlanRevisions';
import clientSupportPlans from './clientSupportPlans';

export const clients = {
  budgets,
  cataloguePricePlanRevisions,
  cataloguePricePlans,
  clientSupportPlans,
  clientSupportPlanRevisions,
};

export default combineReducers(clients);
