import axios from 'axios';

import { buildSlice, CommonState } from '~libs/reduxUtils';

import { getAll, typePrefix } from '~clients/actions/clientSupportPlanRevisions';

interface ClientSupportPlanRevision {
  id: string;
  plan_id: string;
  sequence: number;
  name: string;
}

const initialState: CommonState<ClientSupportPlanRevision> = {
  loading: 'idle',
  hasLoaded: false,
  error: null,
  rows: [],
};

const slice = buildSlice<CommonState<ClientSupportPlanRevision>, ClientSupportPlanRevision>(typePrefix, initialState, {
  commonConfig: { getAll },
});

/**
 * TEMPORARY - remove after API integration
 */
const stub: ClientSupportPlanRevision[] = [
  {
    id: 'd4698178-5101-442c-a87f-332ad7dc3eb8',
    plan_id: '669bde8a-8acc-4fed-bc67-546986de97fd',
    sequence: 1,
    name: 'Plan A - Revision 1',
  },
  {
    id: '6535f69e-a526-4bd6-bccf-147023a9c61b',
    plan_id: '669bde8a-8acc-4fed-bc67-546986de97fd',
    sequence: 2,
    name: 'Plan A - Revision 2',
  },
  {
    id: '60368fad-5683-424a-87dd-1477801378c0',
    plan_id: '3a75b209-3552-4292-a810-dc4fb2528eed',
    sequence: 1,
    name: 'Plan B - Revision 1',
  },
  {
    id: '107cdc2c-f8b1-444a-9909-dae2ddb828f5',
    plan_id: '3a75b209-3552-4292-a810-dc4fb2528eed',
    sequence: 2,
    name: 'Plan B - Revision 2',
  },
  {
    id: 'd8d1b3d5-828c-402b-a2cb-14cfb4a2c720',
    plan_id: '3a75b209-3552-4292-a810-dc4fb2528eed',
    sequence: 1,
    name: 'Plan B - Revision 3',
  },
  {
    id: 'd9a3dc73-7779-4ada-8818-ad6c80529132',
    plan_id: 'be084703-d866-445b-9209-2fa25d3174fb',
    sequence: 2,
    name: 'Plan C - Revision 1',
  },
];

axios.interceptors.response.use((response) => {
  if (response.config.url && response.config.url.match(/\/clients\/support-plans\/.+\/revisions(\/|$)/)) {
    const planId = response.config.url.split('/').slice(-2, -1)[0];
    const filteredStub = stub.filter((item) => item.plan_id === planId);
    response.data = { success: true, message: `Filtered support plan revisions for ${planId}`, data: filteredStub };
  }

  return response;
});

// default actions
export const { clear, updateParams } = slice.actions;
export default slice.reducer;
