import { buildSlice, CommonState, Row } from '~libs/reduxUtils';
import { AwardAlert, AwardAlertVisibility } from '~weekly-planner/lib/common';

import { getAwardAlerts, getAwardAlertTypes, typePrefix } from '~weekly-planner/actions/awardAlerts';

interface State extends CommonState<Row> {
  params: { [key: string]: any };
  awardAlertTypes: Array<AwardAlert>;
  awardAlertsVisibilities: AwardAlertVisibility;
  count?: number;
}

const initialState: State = {
  loading: 'idle',
  hasLoaded: false,
  error: null,
  rows: [],
  params: { limit: 30 },
  awardAlertTypes: [],
  awardAlertsVisibilities: {},
};

const slice = buildSlice<State>(typePrefix, initialState, {
  extraReducers: (builder) => {
    builder.addCase(getAwardAlertTypes.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.awardAlertsVisibilities = action.payload.data.reduce((acc: AwardAlertVisibility, alert: AwardAlert) => {
        acc[alert.tag] = true;
        return acc;
      }, {});
      state.awardAlertTypes = action.payload.data;
      state.hasLoaded = true;
    });
    builder.addCase(getAwardAlerts.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      const { data } = action.payload;
      const { rows, total } = data;
      state.rows = rows;
      state.count = total;
      state.hasLoaded = true;
    });
  },

  customActions: {
    setFilterOptions(state, action) {
      state.filterOptions = action.payload;
    },
    toggleAwardAlertVisibility(state, action) {
      const { key, toggle } = action.payload;
      state.awardAlertsVisibilities[key] = toggle;
    },
    clear(state) {
      state.rows = initialState.rows;
      state.params = initialState.params;
    },
  },
});

export const { clear, updateParams, setFilterOptions, toggleAwardAlertVisibility } = slice.actions;
export default slice.reducer;
