import { apiClientSupportPlans } from '~constants/api';

import { get } from '~libs/genericAction';
import { createThunks, Params } from '~libs/reduxUtils';

export const typePrefix = 'client-support-plan-revisions';

const config = [
  {
    actionType: 'getAll',
    apiPath: (params: Params) => `${apiClientSupportPlans}/${params.plan_id}/revisions`,
    method: get,
  },
];

export const { getAll } = createThunks(typePrefix, config);
