import { apiWeeklyPlanner } from '~constants/api';

import { get } from '~libs/genericAction';
import { createThunks } from '~libs/reduxUtils';

export const typePrefix = 'weekly-planner-award-alerts';

const config = [
  { actionType: 'getAwardAlertTypes', apiPath: `${apiWeeklyPlanner}/award-alerts`, method: get },
  {
    actionType: 'getAwardAlerts',
    apiPath: `${apiWeeklyPlanner}/users/award-alerts`,
    method: get,
    actionParams: { care_worker: true, disabled: false },
  },
];

export const { getAwardAlertTypes, getAwardAlerts } = createThunks(typePrefix, config);
