import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Collapse } from 'reactstrap';

import { AuthTags } from '~constants/authTags';

import { useSearch } from '~libs/searchParams';

import { SearchBar } from '~components/FormFields/Search';
import Icon, { faMagnifyingGlass, faMoneyBill, faPlus } from '~components/Icon';
import { Button, ContainerHeader, TableView } from '~components/index';

import columns from '~clients/forms/budgetsList';
import searchFields from '~clients/forms/budgetsSearch';

import { getAll } from '~clients/actions/budgets';
import { setTitle } from '~main/actions/login';

import { clear } from '~clients/reducers/budgets';

import { selectBudgets, selectBudgetsLoading, selectBudgetsTotal } from '~clients/selectors/budgets';

const Budgets: React.FC = () => {
  const dispatch = useAppDispatch();
  const authTagUpdate = AuthTags.CLIENTS.BUDGETS.UPDATE;

  const [searchBarOpen, setSearchBarOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearch('search', {});

  // const initialSortState: SortingState = [
  //   {
  //     id: 'client.full_name',
  //     desc: true,
  //   },
  // ];

  useEffect(() => {
    setTitle(`Budgets`);
    load();

    return () => {
      dispatch(clear([]));
    };
  }, []);

  const load = async (loadParams: { [key: string]: any } = {}) => {
    console.log(loadParams); // TO BE RE-ADDED LATER
    // const newParams = {
    //   ...params,
    //   ...loadParams,
    // };
    dispatch(getAll());
    // setParams(newParams);
  };

  const search = (values: { [key: string]: any }) => setSearchParams(values);

  const budgets = useAppSelector(selectBudgets);
  const budgetsTotal = useAppSelector(selectBudgetsTotal);
  const budgetsLoading = useAppSelector(selectBudgetsLoading);
  const isLoading = budgetsLoading === 'pending';

  return (
    <div className="p-2">
      <ContainerHeader icon={faMoneyBill} iconSize="1x" className="info" dataCy="budgets-header">
        Budgets
      </ContainerHeader>
      <div className="nav d-flex justify-content-between bg-light p-2 mt-2 mb-2">
        <Button
          authTag={authTagUpdate}
          dataCy="budgets-new-button"
          size="sm"
          color="success"
          className="ms-2"
          href={'/clients/budgets/new'}
        >
          <Icon className="me-2" icon={faPlus} />
          Add New Budget
        </Button>
        <Button
          dataCy="budgets-search-button"
          size="sm"
          className="me-2"
          onClick={() => setSearchBarOpen(!searchBarOpen)}
        >
          <Icon className="me-2" icon={faMagnifyingGlass} />
          {searchBarOpen ? 'Hide' : 'Search'}
        </Button>
      </div>
      <Collapse isOpen={searchBarOpen}>
        <SearchBar
          data-cy={'budgets-search-bar'}
          fields={searchFields}
          initialValues={searchParams}
          storageKey="budgets"
          onSubmit={search}
        />
      </Collapse>
      <TableView
        id="budgets"
        dataCy="budgets-table"
        data={budgets as []}
        columns={columns}
        isLoading={isLoading}
        emptyIcon={faMoneyBill}
        emptyMessage={'No budgets found'}
        allowSort={true}
        allowPagination={true}
        onLoadMore={() => load({ limit: 50 })} //params.limit + 50
        showCount={true}
        totalCount={budgetsTotal}
      />
    </div>
  );
};

export default Budgets;
