import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Col, Row } from 'reactstrap';
import dayjs from 'dayjs';

import { AuthTags } from '~constants/authTags';
import { getTP1Urls } from '~constants/routes-tp1';

import { isBeforeDay } from '~libs/dayjs';

import { InputProps } from '~components/FormFields';
import * as FormInput from '~components/FormFields/Inputs';

import { getAll as getCareWorkers } from '~care-workers/actions';

import { selectLookupCareWorkers, selectUserByID } from '~care-workers/selectors/users';
import { selectClientByID } from '~main/selectors/clients/clients';
import { selectUserTags } from '~main/selectors/login';

const FormInputCareWorkers: React.FC<InputProps> = ({ id, value = {}, disabled, onChange, manualReload = true }) => {
  const dispatch = useAppDispatch();
  const [filterValue, setFilterValue] = useState('all');
  const [selectorParams, setSelectorParams] = useState({ filter_by: { client_id: '', preference: 'all' } });
  const { client_id, date = dayjs().add(1, 'day'), user_id = '', id: appointmentId, repeat } = value;

  const client = useAppSelector((state) => selectClientByID(state, parseInt(client_id)));
  const careWorker = useAppSelector((state) => selectUserByID(state, parseInt(user_id)));
  const tags = useAppSelector(selectUserTags);

  const authorised = tags.includes(AuthTags.APPOINTMENTS.UPDATE);

  //none of the links we want use the user, settings or data so we pass empty to reduce loading requirements
  const {
    CAREWORKERS: { CareWorkerDetails },
  } = getTP1Urls({}, {}, {});
  const url = `${CareWorkerDetails}${user_id}`;

  const { preferences } = client ?? {};
  const { gender = null, yes = [], no = [] } = preferences ?? {};

  const isNewAppointment = !appointmentId;

  const isReadOnly = !isNewAppointment && !repeat && isBeforeDay(dayjs(date), dayjs());

  const options = [
    { id: 'all', name: 'All Workers' },
    { id: 'preferred', name: 'Preferred Workers' },
    { id: 'historical', name: 'Historical Workers' },
  ];

  useEffect(() => {
    setSelectorParams({
      filter_by: {
        client_id,
        preference: filterValue,
      },
    });
  }, [client_id, filterValue]);

  useEffect(() => {
    dispatch(getCareWorkers(selectorParams));
  }, [selectorParams]);

  let description = '';
  let descriptionColor = 'info';
  if (no?.includes(parseInt(user_id))) {
    description = 'Client does not prefer this worker';
    descriptionColor = 'danger';
  } else if (yes?.includes(parseInt(user_id))) {
    description = 'Client prefers this worker';
    descriptionColor = 'success';
  } else if (gender && gender > 0) {
    let genderPreference = '';
    if (gender === 1) genderPreference = 'male';
    else if (gender === 2) genderPreference = 'female';
    description = `NOTE: Client prefers ${genderPreference} workers`;
  }

  return (
    <>
      <Row className="align-items-center">
        {isReadOnly ? (
          <Col>
            <FormInput.ReadOnly
              type="readonly"
              id={id}
              name={id}
              value={careWorker?.full_name}
              link={authorised ? url : undefined}
            />
          </Col>
        ) : (
          <>
            <Col sm={8}>
              <FormInput.Select
                type="select"
                id={id}
                name={id}
                value={user_id}
                disabled={disabled}
                onChange={onChange}
                selectorOptions={selectLookupCareWorkers}
                selectorActions={getCareWorkers}
                selectorParams={selectorParams}
                manualReload={manualReload}
              />
            </Col>
            <Col className="d-flex align-items-center">
              <span className="ms-3 me-2">Show</span>
              <FormInput.Select
                type="select"
                name={`filter_${id}`}
                id={`filter_${id}`}
                value={filterValue}
                clearable={false}
                onChange={(_, value) => setFilterValue(value)}
                options={options}
                disabled={!client_id || disabled}
                ignorePlaceholder={true}
              />
            </Col>
          </>
        )}
      </Row>
      {description.length > 0 && (
        <Row className="align-items-center">
          <Col>
            <div className={`pt-1 ps-2 pe-2 small text-${descriptionColor}`}>{description}</div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default FormInputCareWorkers;
