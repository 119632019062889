import { buildSlice, CommonState } from '~libs/reduxUtils';
import { Worker } from '~weekly-planner/lib/common';

import { getAll, typePrefix } from '~weekly-planner/actions/users';

interface State extends CommonState<Worker> {
  params: { [key: string]: any };
  filterOptions: { [key: string]: any };
  orderBy: {
    availability: false;
    distance: false;
  };
}

const initialState: State = {
  loading: 'idle',
  hasLoaded: false,
  error: null,
  params: {},
  rows: [],
  filterOptions: {
    is_qualified: null,
    is_available: null,
    is_preferred: null,
    is_historical: null,
  },
  orderBy: {
    availability: false,
    distance: false,
  },
};

const slice = buildSlice<State, Worker>(typePrefix, initialState, {
  customActions: {
    filterUsers(state, action) {
      const { payload } = action;
      if ('key' in payload && 'value' in payload) {
        state.filterOptions[payload.key] = payload.value;
      } else {
        Object.entries(payload).forEach(([key, value]) => {
          state.filterOptions[key] = value;
        });
      }
    },
    orderUsers(state, action) {
      const { payload } = action;
      state.orderBy = payload;
    },
  },
  commonConfig: { getAll },
});

export const { clear, updateParams, filterUsers, orderUsers } = slice.actions;
export default slice.reducer;
