type Derive = (values: any) => any;

export const derivePricePlan: Derive = ({ price_plan_id, price_plan_revision_id }) => ({
  price_plan_id,
  price_plan_revision_id,
});

export const deriveSupportPlan: Derive = ({ support_plan_id, support_plan_revision_id }) => ({
  support_plan_id,
  support_plan_revision_id,
});
