import axios from 'axios';

import { buildSlice, CommonState } from '~libs/reduxUtils';

import { getAll, typePrefix } from '~clients/actions/clientSupportPlans';

interface ClientSupportPlan {
  id: string;
  name: string;
}

const initialState: CommonState<ClientSupportPlan> = {
  loading: 'idle',
  hasLoaded: false,
  error: null,
  rows: [],
};

const slice = buildSlice<CommonState<ClientSupportPlan>, ClientSupportPlan>(typePrefix, initialState, {
  commonConfig: { getAll },
});

/**
 * TEMPORARY - remove after API integration
 */
const stub: ClientSupportPlan[] = [
  {
    id: '669bde8a-8acc-4fed-bc67-546986de97fd',
    name: 'Support Plan A',
  },
  {
    id: '3a75b209-3552-4292-a810-dc4fb2528eed',
    name: 'Support Plan B',
  },
  {
    id: 'be084703-d866-445b-9209-2fa25d3174fb',
    name: 'Support Plan C',
  },
];

axios.interceptors.response.use((response) => {
  if (response.config.url && response.config.url.match(/\/clients\/support-plans(\/|$)/)) {
    if (!response.config.url.includes('/revisions')) {
      response.data = { success: true, message: 'SUPPORT PLAN: Stub data', data: stub };
    }
  }

  return response;
});

export const { clear, updateParams } = slice.actions;
export default slice.reducer;
