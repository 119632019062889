import React from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { isBeforeDay, weeklyPlannerDayDisplayFormat } from '~libs/dayjs';

interface componentProps {
  publishDate: string;
  date: string;
}
export const PublishPill: React.FC<componentProps> = ({ date, publishDate }) => {
  const startOfWeek = dayjs(date).weekday(0);
  const endOfWeek = startOfWeek.add(6, 'days');
  const published = !isBeforeDay(dayjs(publishDate), startOfWeek);
  const partialPublish = isBeforeDay(dayjs(publishDate), endOfWeek);

  const publishMarkerClassName = classNames('published-state', {
    published: published,
    partial: partialPublish && published,
  });

  return (
    <div className={publishMarkerClassName} data-cy="weekly-planner-publish-status-pill">
      <span>
        {published
          ? partialPublish
            ? `PARTIALLY Published to ${dayjs(publishDate).format(weeklyPlannerDayDisplayFormat)}`
            : 'PUBLISHED'
          : 'NOT Published'}
      </span>
    </div>
  );
};
