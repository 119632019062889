import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const ClientSupportPlansState = (state: RootState) => state.clients.clientSupportPlans;

export const selectClientSupportPlans = (state: RootState) => ClientSupportPlansState(state).rows;
export const selectClientSupportPlansLoading = (state: RootState) => ClientSupportPlansState(state).loading;

export const selectClientSupportPlanById = createSelector(
  [selectClientSupportPlans, (_: RootState, id?: string) => id],
  (rows, id) => rows.find(({ id: rowId }) => id === rowId),
);
