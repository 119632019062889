import React from 'react';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import Icon from '~components/Icon/Icon';

interface ComponentProps {
  key: string;
  icons: FontAwesomeIconProps[];
  title?: string;
  className?: string;
}

const IconStack: React.FC<ComponentProps> = ({ key, icons, title, className }) => {
  const stackClassNames = classNames('fa-layers fa-fw', {
    [`${className}`]: className !== undefined,
  });

  return (
    <span key={key} className={stackClassNames} title={title}>
      {icons.map(({ icon, className, size, style, transform }, index) => (
        <Icon
          key={`${index}-${key}`}
          icon={icon}
          size={size}
          className={className}
          style={style}
          transform={transform}
        />
      ))}
    </span>
  );
};

export default IconStack;
