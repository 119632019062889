import React, { useEffect } from 'react';
import { useAppDispatch } from 'hooks';

import { InputProps } from '../props';
import { Select } from './';

const CascadingSelectors: React.FC<InputProps> = ({ value = {}, onChange, cascadingSelectors = [] }) => {
  const dispatch = useAppDispatch();

  // Sort cascadingSelectors by position to ensure proper order
  const sortedSelectors = [...cascadingSelectors].sort((a, b) => a.position - b.position);

  // Calculate dynamic CSSProperties for visible selectors
  const style = {
    '--visible-selectors': cascadingSelectors.filter(
      (_, index) => index === 0 || value[cascadingSelectors[index - 1].id],
    ).length,
  } as React.CSSProperties;

  // Handle changes for any select in the hierarchy
  const handleChange = (name: string, val: any) => {
    const selector = sortedSelectors.find((sel) => sel.name === name);
    if (!selector) return;

    const { id, fKey = 'id' } = selector;

    // Update the current value
    onChange && onChange(id, val);

    // Clear options for all lower selectors
    const lowerSelectors = sortedSelectors.filter((s) => s.position > selector.position);
    lowerSelectors.forEach(({ id, reducerFunctions }) => {
      if (reducerFunctions?.clear) {
        dispatch(reducerFunctions.clear([]));
      }

      // update lower selector values
      onChange && onChange(id, undefined);
    });

    // Fetch new options for the next selector in the chain
    const nextSelector = sortedSelectors.find((s) => s.position === selector.position + 1);
    if (nextSelector && val) {
      const params = { [fKey]: val }; // Use dynamic key for link
      dispatch(nextSelector.selectorActions(params));
    }
  };

  useEffect(() => {
    // fetch highest order selector on mount
    const firstSelector = sortedSelectors.find((s) => s.position === 1);
    if (firstSelector) {
      dispatch(firstSelector.selectorActions());
    }
  }, []);

  return (
    <div className="cascading-selector" style={style}>
      {cascadingSelectors.map((selector, index) => {
        const { id, label, selectorOptions } = selector;

        const isVisible = index === 0 || value[cascadingSelectors[index - 1].id];
        return (
          isVisible && (
            <div key={id} className={`cascading-selector-container`}>
              {label && (
                <label htmlFor={id} className="cascading-label">
                  {label}:
                </label>
              )}
              <div className="cascading-select">
                <Select
                  type="select"
                  id={id}
                  name={id}
                  value={value[id] || undefined}
                  onChange={(name, val) => handleChange(name, val)}
                  selectorOptions={selectorOptions}
                  className="w-100"
                />
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default CascadingSelectors;
