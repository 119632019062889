import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import ReactSelect from 'react-select';
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Spinner as ReactSpinner,
} from 'reactstrap';
import dayjs, { Dayjs } from 'dayjs';

import { WeeklyPlannerDatasetOptions, WeeklyPlannerDateRangeOptions } from '~constants/maps';

import { dateInputFieldFormat } from '~libs/dayjs';
import { LoadingState } from '~libs/reduxUtils';
import { useSearch } from '~libs/searchParams';

import Button from '~components/Button';
import ContainerHeader from '~components/ContainerHeader';
import { SearchBar } from '~components/FormFields/Search';
import Icon, { faGear, faInfoCircle, faMagnifyingGlass, faMapPin } from '~components/Icon';
import Modal from '~components/Modal';
import WeekPicker from '~components/WeekPicker';
import Legend from '~weekly-planner/components/Legend';
import {
  AppointmentExceptions,
  Changes,
  HoldPeriods,
  ServiceColour,
  UserExceptions,
} from '~weekly-planner/components/Modal';

import searchFields from '~weekly-planner/forms/search';

import { clear as clearAppointments } from '~weekly-planner/reducers/appointments/appointments';
import { clear as clearConflicts } from '~weekly-planner/reducers/appointments/conflicts';
import { clear as clearMissingQualifications } from '~weekly-planner/reducers/appointments/missingQualifications';
import { clear as clearRejected } from '~weekly-planner/reducers/appointments/rejected';
import { clear as clearUnallocated } from '~weekly-planner/reducers/appointments/unallocated';
import { clear as clearAwardAlerts } from '~weekly-planner/reducers/awardAlerts';
import { clear as clearUsers, updateParams as updateUserParams } from '~weekly-planner/reducers/users/users';
import {
  clear as clearWeeklyPlannerData,
  focusAppointment,
  focusWorker,
  toggleOption,
  update,
  updateParams,
  updatePublishDate,
  updateWithPersistence,
} from '~weekly-planner/reducers/weeklyPlanner';

import { selectCompanySettings } from '~main/selectors/login';
import {
  selectDate,
  selectOptions,
  selectPublishDate,
  selectRangeType,
  selectViewType,
} from '~weekly-planner/selectors';
import { selectExceptions, selectOnHold } from '~weekly-planner/selectors/appointments';
import { selectRunningLate } from '~weekly-planner/selectors/appointments/runningLate';
import { selectAllLoading } from '~weekly-planner/selectors/simulate';
import { selectParams as selectUserParams } from '~weekly-planner/selectors/users';

import DropDownOption from './DropDownOption';
import { PublishPill } from './PublishPill';

interface ComponentProps {
  onRefresh?: (searchParams?: { repeat: boolean; sort: string[]; limit: number }) => void;
  loadingState: LoadingState;
}

const Banner: React.FC<ComponentProps> = ({ onRefresh, loadingState }) => {
  const viewOptions = Object.values(WeeklyPlannerDatasetOptions).map((option) => ({
    value: option,
    label: option,
  }));
  const dateRangeOptions = Object.values(WeeklyPlannerDateRangeOptions).map((option) => ({
    value: option,
    label: option,
  }));

  const dispatch = useAppDispatch();

  const {
    appointmentFlexibility,
    workerAvailability,
    workerQualifications,
    workerAwardAlerts,
    totalSimple,
    totalAppointments,
    totalPredicted,
    totalDistance,
    showTravel,
    showBreaks,
    showTeaBreaks,
    showServiceType,
    showCancellations,
    showPayableCancellations,
  } = useAppSelector(selectOptions);
  const { rescheduled, cancelled } = useAppSelector(selectExceptions);
  const onHold = useAppSelector(selectOnHold);
  const { appointmentsBeforeNow, appointmentsNow, notLoggedIn } = useAppSelector(selectRunningLate);
  const viewType = useAppSelector(selectViewType);
  const rangeType = useAppSelector(selectRangeType);
  const settings = useAppSelector(selectCompanySettings);
  const date = useAppSelector(selectDate);
  const publishDate = useAppSelector(selectPublishDate);
  const isAllLoading = useAppSelector(selectAllLoading);
  const userParams = useAppSelector(selectUserParams);

  const exceptionCount = rescheduled.length + cancelled.length;
  const onHoldCount = onHold.length;
  const runningLateCount = appointmentsBeforeNow.length + appointmentsNow.length + notLoggedIn.length;

  const initialSearchState: any = {};

  const [isChangesOpen, setIsChangesOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isLegendOpen, setIsLegendOpen] = useState(false);
  const [isColourEditOpen, setColourEditOpen] = useState<boolean>(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

  useEffect(() => {
    if (!publishDate) {
      const currentPublishDate = settings.published_date as string;
      dispatch(updatePublishDate(currentPublishDate));
    }
  }, []);

  const [modalProperties, setShowInfoModalProperties] = useState<{
    header: string;
    body: 'late' | 'hold' | 'exception' | null;
  }>({
    header: '',
    body: null,
  });

  const [searchParams, setSearchParams] = useSearch('search', initialSearchState);
  const search = (values: { [key: string]: any }) => setSearchParams(values);

  const isLoading = isAllLoading || loadingState === 'pending';

  const onDateChange = (value: Dayjs) => {
    const isDaily = rangeType === WeeklyPlannerDateRangeOptions.DAILY;
    const weekStart = value.weekday(0);

    dispatch(update({ date: value.format(dateInputFieldFormat) }));

    dispatch(
      updateParams({
        week_start: weekStart.format(dateInputFieldFormat),
        week_end: isDaily ? weekStart.add(1, 'day').format(dateInputFieldFormat) : null,
        limit: 30,
      }),
    );
  };

  const changeOption = (value: boolean, includeKey: string, key: string) => {
    const newValue = !value;
    const params = {
      include: {
        ...userParams.include,
        [includeKey]: newValue,
      },
    };
    dispatch(toggleOption({ key, toggle: newValue }));
    dispatch(updateUserParams(params));
  };

  const changeView = (value: any) => {
    resetUnchangedData();
    dispatch(updateWithPersistence({ viewType: value }));
    dispatch(updateParams({ limit: 30 }));
  };

  const changeRange = (value: any) => {
    resetUnchangedData();
    resetSidebarData();
    dispatch(updateWithPersistence({ rangeType: value }));
    const isDaily = value === WeeklyPlannerDateRangeOptions.DAILY;
    const weekStart = dayjs(date).weekday(0);

    dispatch(
      updateParams({
        week_start: weekStart.format(dateInputFieldFormat),
        week_end: isDaily ? weekStart.add(1, 'day').format(dateInputFieldFormat) : null,
        limit: 30,
      }),
    );
  };

  const resetUnchangedData = () => {
    dispatch(clearUsers([]));
    dispatch(clearWeeklyPlannerData([]));
    dispatch(focusAppointment(null));
    dispatch(focusWorker(null));
  };

  const resetSidebarData = () => {
    dispatch(clearAppointments([]));
    dispatch(clearConflicts([]));
    dispatch(clearMissingQualifications([]));
    dispatch(clearRejected([]));
    dispatch(clearUnallocated([]));
    dispatch(clearAwardAlerts([]));
  };

  useEffect(() => {
    if (searchParams !== initialSearchState) {
      onRefresh?.(searchParams);
    }
  }, [searchParams]);

  return (
    <>
      <ContainerHeader>Weekly Planner</ContainerHeader>
      <Changes
        isOpen={isChangesOpen}
        onClose={() => {
          setIsChangesOpen(false);
          onRefresh && onRefresh();
        }}
      />
      <div className="nav d-flex justify-content-between bg-light p-2 mt-2 mb-2 banner">
        <div className="d-flex">
          <PublishPill date={date} publishDate={publishDate} />
          <div className="d-flex align-items-center ms-1 me-2" data-cy={'weekly-planner-global-loader'}>
            {isLoading && <ReactSpinner />}
          </div>
        </div>
        <div className="d-flex">
          <ServiceColour isOpen={isColourEditOpen} onClose={() => setColourEditOpen(!isColourEditOpen)} />
          <div className="d-flex align-items-center ms-1">
            <Label className="fw-bold m-2">View:</Label>
            <ReactSelect
              id="dataset-view-select"
              name="dataset-view-select"
              data-cy="weekly-planner-dataset-view-select"
              options={viewOptions}
              value={viewOptions.find((option) => option.value === viewType)}
              defaultValue={viewOptions[0]}
              closeMenuOnSelect={true}
              onChange={(option) => changeView(option?.value)}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
            />
            <Label className="fw-bold m-2 ps-2">Range:</Label>
            <ReactSelect
              id="dataset-range-select"
              name="dataset-range-select"
              data-cy="weekly-planner-dataset-range-select"
              options={dateRangeOptions}
              value={dateRangeOptions.find((option) => option.value === rangeType)}
              defaultValue={dateRangeOptions[0]}
              closeMenuOnSelect={true}
              onChange={(option) => changeRange(option?.value)}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
            />
          </div>

          <WeekPicker onChange={onDateChange} />
          <Button size="sm" className="me-2" onClick={() => setIsSearchBarOpen(!isSearchBarOpen)}>
            <Icon className="me-2" icon={faMagnifyingGlass} />
            {isSearchBarOpen ? 'Hide' : 'Search'}
          </Button>

          <Dropdown isOpen={isViewOpen} toggle={() => setIsViewOpen(!isViewOpen)} direction="down" className="mx-2">
            <DropdownToggle size="sm" className={`settings-icon ${isViewOpen ? 'active' : ''}`}>
              <Icon icon={faGear} className="align-self-center" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Planner Options</DropdownItem>
              <DropDownOption
                iconToggle={showCancellations}
                onClick={() => changeOption(showCancellations, 'show_cancellations', 'showCancellations')}
                label="Cancellations"
              />
              <DropDownOption
                iconToggle={showPayableCancellations}
                onClick={() =>
                  changeOption(showPayableCancellations, 'show_payable_cancellations', 'showPayableCancellations')
                }
                label="Payable Cancellations"
              />
              <DropdownItem header>Worker Details</DropdownItem>
              <DropDownOption
                iconToggle={workerQualifications}
                onClick={() => dispatch(toggleOption({ key: 'workerQualifications', toggle: !workerQualifications }))}
                label="Qualifications"
              />
              <DropDownOption
                iconToggle={workerAvailability}
                onClick={() => dispatch(toggleOption({ key: 'workerAvailability', toggle: !workerAvailability }))}
                label="Availability"
              />
              <DropdownItem header>Planner</DropdownItem>
              <DropDownOption
                iconToggle={appointmentFlexibility}
                onClick={() =>
                  dispatch(toggleOption({ key: 'appointmentFlexibility', toggle: !appointmentFlexibility }))
                }
                label="Flexibility"
              />
              <DropDownOption
                iconToggle={showServiceType}
                onClick={() => dispatch(toggleOption({ key: 'showServiceType', toggle: !showServiceType }))}
                label="Service Types"
              />
              <DropDownOption
                iconToggle={showBreaks}
                onClick={() => dispatch(toggleOption({ key: 'showBreaks', toggle: !showBreaks }))}
                label="Breaks"
              />
              <DropDownOption
                iconToggle={showTeaBreaks}
                onClick={() => changeOption(showTeaBreaks, 'tea_break', 'showTeaBreaks')}
                label="Tea Breaks"
              />
              <DropDownOption
                iconToggle={showTravel}
                onClick={() => dispatch(toggleOption({ key: 'showTravel', toggle: !showTravel }))}
                label="Travel"
              />
              <DropdownItem header>Totals</DropdownItem>
              <DropDownOption
                iconToggle={workerAwardAlerts}
                onClick={() => dispatch(toggleOption({ key: 'workerAwardAlerts', toggle: !workerAwardAlerts }))}
                label="Award Alerts"
              />
              <DropDownOption
                iconToggle={totalSimple}
                onClick={() => dispatch(toggleOption({ key: 'totalSimple', toggle: !totalSimple }))}
                label="Simple"
              />
              <DropDownOption
                iconToggle={totalAppointments}
                onClick={() => dispatch(toggleOption({ key: 'totalAppointments', toggle: !totalAppointments }))}
                label="Appointments"
              />
              <DropDownOption
                iconToggle={totalPredicted}
                onClick={() => dispatch(toggleOption({ key: 'totalPredicted', toggle: !totalPredicted }))}
                label="Predicted Shift"
              />
              <DropDownOption
                iconToggle={totalDistance}
                onClick={() => dispatch(toggleOption({ key: 'totalDistance', toggle: !totalDistance }))}
                label="Distance"
              />
            </DropdownMenu>
          </Dropdown>

          <Dropdown isOpen={isInfoOpen} toggle={() => setIsInfoOpen(!isInfoOpen)} direction="down" className="mx-1">
            <DropdownToggle size="sm" className={`settings-icon ${isInfoOpen ? 'active' : ''}`}>
              <Icon icon={faInfoCircle} className="align-self-center" />
            </DropdownToggle>
            <DropdownMenu>
              <DropDownOption
                iconToggle={onHoldCount > 0}
                onClick={() => {
                  setShowInfoModal(true);
                  setShowInfoModalProperties({
                    header: 'On Hold',
                    body: 'hold',
                  });
                }}
                label={`On Hold (${onHoldCount})`}
              />
              <DropDownOption
                iconToggle={runningLateCount > 0}
                onClick={() => {
                  setShowInfoModal(true);
                  setShowInfoModalProperties({
                    header: 'Running Late',
                    body: 'late',
                  });
                }}
                label={`Running Late (${runningLateCount})`}
              />
              <DropDownOption
                iconToggle={exceptionCount > 0}
                onClick={() => {
                  setShowInfoModal(true);
                  setShowInfoModalProperties({
                    header: 'Exceptions',
                    body: 'exception',
                  });
                }}
                label={`Exceptions (${exceptionCount})`}
              />
            </DropdownMenu>
          </Dropdown>

          <Dropdown
            isOpen={isLegendOpen}
            toggle={() => setIsLegendOpen(!isLegendOpen)}
            direction="down"
            className="mx-1"
          >
            <DropdownToggle size="sm" className={`settings-icon ${isLegendOpen ? 'active' : ''}`}>
              <Icon icon={faMapPin} className="align-self-center w-10 h-10" />
            </DropdownToggle>
            <DropdownMenu>
              <Legend />
            </DropdownMenu>
          </Dropdown>
        </div>
        <Modal
          isOpen={showInfoModal}
          setIsOpen={setShowInfoModal}
          header={modalProperties.header}
          scrollable
          size="xl"
          centered
        >
          {modalProperties.body === 'exception' && <AppointmentExceptions />}
          {modalProperties.body === 'late' && <UserExceptions />}
          {modalProperties.body === 'hold' && <HoldPeriods />}
        </Modal>
      </div>
      <Collapse isOpen={isSearchBarOpen}>
        <SearchBar fields={searchFields} initialValues={searchParams} storageKey="weekly-planner" onSubmit={search} />
      </Collapse>
    </>
  );
};

export default Banner;
