import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks';

import ContainerHeader from '~components/ContainerHeader';
import { faPlus } from '~components/Icon';
import WizardForm from '~components/WizardForm';

import newBudgetFields from '~clients/forms/newBudget';

import { getAll as getClients } from '~main/actions/clients';

import { selectErrorResponse } from '~appointments/selectors/appointments';

// REQUIRED?
interface Budget {
  client_id?: number;
  support_plan_id?: string;
  support_plan_revision_id?: string;
  price_plan_id?: string;
  price_plan_revision_id?: string;
  start_date?: string;
  end_date?: string;
  sah_level?: number;
  [key: string]: string | number | null | undefined;
}

const NewBudget: React.FC = () => {
  const dispatch = useAppDispatch();
  const [initValues, setInitValues] = useState<Budget>({});

  const onAddBudget = async (values: any) => {
    console.log(values);
    console.log('ADD BUDGET TRIGGERED');

    // dispatch & create here, return to budgets page after processing?
    setInitValues({});
    return true;
  };

  useEffect(() => {
    dispatch(getClients());
  }, []);

  return (
    <div className="p-2">
      <ContainerHeader icon={faPlus} iconSize="1x" className="info" dataCy="new-budgets-header">
        New Budget
      </ContainerHeader>
      <WizardForm
        steps={newBudgetFields}
        initialValues={initValues}
        errorSelector={selectErrorResponse} // REPLACE LATER
        onComplete={onAddBudget} //TODO LATER
      />
    </div>
  );
};

export default NewBudget;
