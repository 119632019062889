import React, { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Col, Container, Row } from 'reactstrap';
import dayjs from 'dayjs';
import { throttle } from 'lodash';

import { weeklyPlannerDayDisplayFormat } from '~libs/dayjs';

import Button from '~components/Button';
import Icon, { faEye } from '~components/Icon';
import Spinner from '~components/Spinner';
import { AwardAlertViewMenu } from '~weekly-planner/components/SideBar/AwardAlerts';

import { getAwardAlerts } from '~weekly-planner/actions/awardAlerts';

import { clear, updateParams } from '~weekly-planner/reducers/awardAlerts';
import { focusWorker } from '~weekly-planner/reducers/weeklyPlanner';

import {
  selectAllAwards,
  selectAwardAlertsVisibilities,
  selectParams,
} from '~weekly-planner/selectors/appointments/awardAlerts';
import { selectHasLoaded, selectLoading } from '~weekly-planner/selectors/users';

interface ComponentProps {
  weekStart: string;
}

const AwardAlerts: React.FC<ComponentProps> = React.memo(({ weekStart: wpWeekStart }) => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParams);
  const loading = useAppSelector(selectLoading);
  const hasLoaded = useAppSelector(selectHasLoaded);
  const alerts = useAppSelector(selectAllAwards);
  const alertVisibilities = useAppSelector(selectAwardAlertsVisibilities);

  const isLoading = loading === 'pending';
  const { limit, week_start } = params;

  // Load on scroll
  const containerRef = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(
    throttle(async () => {
      if (!containerRef.current || isLoading) return;

      const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
      const nearBottom = scrollHeight - (scrollTop + clientHeight) < 50;

      const hasRemainingRows = limit <= alerts.length;

      if (!isLoading && nearBottom && hasRemainingRows) {
        dispatch(updateParams({ limit: limit + 30 }));
      }
    }, 200),
    [limit, dispatch, isLoading],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener('scroll', onScroll, { passive: true });
    return () => container.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  // Fetch data on param change
  useEffect(() => {
    if (week_start && limit) {
      dispatch(
        getAwardAlerts({
          ...params,
          include_alerts: Object.keys(alertVisibilities).filter((key) => alertVisibilities[key]),
        }),
      );
    }
  }, [params, alertVisibilities]);

  // Update params on date change
  useEffect(() => {
    if (!hasLoaded || wpWeekStart !== week_start) {
      dispatch(updateParams({ week_start: wpWeekStart, limit: 30 }));
    }
  }, [hasLoaded, wpWeekStart, week_start]);

  // unmount & clear reducer
  useEffect(() => {
    return () => {
      dispatch(clear([]));
    };
  }, []);

  const renderResults = () => {
    return alerts.map(({ date, message, user }, index) => {
      const displayDate = date ? dayjs(date).format(weeklyPlannerDayDisplayFormat) : '-';
      return (
        <Row key={index} className="underline">
          <Col sm={3}>{displayDate}</Col>
          <Col>{user.full_name}</Col>
          <Col>{message}</Col>
          <Col sm={1} className="text-center">
            <Button size="sm" className="bg-white text-dark border-0" onClick={() => dispatch(focusWorker(user.id))}>
              <Icon icon={faEye} />
            </Button>
          </Col>
        </Row>
      );
    });
  };

  const hasData = alerts.length > 0;

  return (
    <div ref={containerRef} className="card-container">
      <Container className="inner-content limited">
        <Container className="award-alerts-container">
          <div className="nav card-header">
            <AwardAlertViewMenu />
          </div>
          {hasData ? (
            <>
              <Row className="fw-bold row underline flex-nowrap">
                <Col sm={3}>Day</Col>
                <Col>Worker</Col>
                <Col>Alert</Col>
                <Col sm={1} />
              </Row>
              {renderResults()}
            </>
          ) : (
            hasLoaded && <p className="no-result">No alerts found.</p>
          )}
          <Spinner loading={isLoading} className="p-5" />
        </Container>
      </Container>
    </div>
  );
});

AwardAlerts.displayName = 'AwardAlerts';
export default AwardAlerts;
