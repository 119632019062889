import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { RootState } from 'store';

import { isUserAvailable } from '~weekly-planner/lib/common';
import { extractEvent } from '~weekly-planner/lib/simulate';

import { selectFocusedAppointment, selectFocusedWorkerId, selectOrderBy } from '~weekly-planner/selectors';
import {
  selectAll as selectAllUnallocatedRecords,
  selectCount as selectTotalUnallocatedCount,
} from '~weekly-planner/selectors/appointments/unallocated';
import { selectAll as selectAllClientChanges } from '~weekly-planner/selectors/clients';
import {
  selectAllChanges as selectAllUnsavedChanges,
  selectAll as selectAllUnsavedRecords,
} from '~weekly-planner/selectors/unsaved';
import { selectAll as selectAllWorkerChanges, selectFilterOptions } from '~weekly-planner/selectors/users';

export const selectIsFocusedWorkerInRows = createSelector(
  [selectAllWorkerChanges, selectFocusedWorkerId],
  (rows: any[], focusedWorkerId: number | null) => {
    if (!focusedWorkerId) return false;
    return rows.some(({ id }) => id === focusedWorkerId);
  },
);

export const selectWorkerFocusedRows = createSelector(
  [selectAllWorkerChanges, selectFocusedWorkerId, selectIsFocusedWorkerInRows],
  (rows, focusedWorkerId, isFocusedWorkerInRows) => {
    if (!focusedWorkerId || !isFocusedWorkerInRows) return rows;

    const focusedRow = rows.find((row) => row.id === focusedWorkerId.toString());
    if (!focusedRow) return rows;

    const otherRows = rows.filter((row) => row.id !== focusedWorkerId.toString());
    return [focusedRow, ...otherRows];
  },
);

// Look for unsaved changes matching the ids of unallocated appointments, do not show if "allocated" but unsaved
export const selectUnallocated = createSelector(
  [selectAllUnallocatedRecords, selectAllUnsavedChanges],
  (unallocated, unsavedAppointments) => unallocated.filter(({ key }) => key !== null && !(key in unsavedAppointments)),
);

export const selectUnallocatedCount = createSelector(
  [selectTotalUnallocatedCount, selectAllUnsavedChanges, selectAllUnallocatedRecords],
  (unallocatedCount, unsavedChanges, unallocatedRows) => {
    // Select count of unallocated rows (this should be the TOTAL)
    let count = unallocatedCount ?? 0;

    // Select all unsaved changes where user_id IS NOT NULL and key matches unallocated rows
    // (ie. user has been assigned to an unallocated appointment)
    const assignedCount = unallocatedRows.filter(
      ({ key }) => key !== null && key in unsavedChanges && unsavedChanges[key].user_id !== null,
    ).length;

    count -= assignedCount;

    // Select all unsaved changes where used_id IS NULL and key does not match unallocated rows
    // (ie. user has been UNAssigned from an allocated appointment)
    const unassignedCount = Object.values(unsavedChanges).filter(({ key, user_id }) => {
      return user_id === null && !unallocatedRows.find((unallocated) => unallocated.key === key);
    }).length;

    count += unassignedCount;

    return count;
  },
);

export const selectSimulatedWorkerChanges = createSelector(
  [selectAllUnsavedRecords, selectWorkerFocusedRows],
  (unsavedRows, savedRows) => {
    const rows = [...savedRows];
    unsavedRows.forEach((unsavedRow: any) => {
      const index = rows.findIndex(({ id }) => id === unsavedRow.id);
      if (index > -1) rows[index] = unsavedRow;
      else rows.push(unsavedRow);
    });
    return rows;
  },
);

export const selectSimulatedClientChanges = createSelector(
  [selectAllUnsavedRecords, selectAllClientChanges],
  (unsavedRows, savedRows) => {
    const rows = [...savedRows];
    unsavedRows.forEach((unsavedRow: any) => {
      const index = rows.findIndex(({ id }) => id === unsavedRow.id);
      if (index > -1) rows[index] = unsavedRow;
      else rows.push(unsavedRow);
    });
    return rows;
  },
);

export const selectAllSimulatedChanges = createSelector(
  [selectSimulatedClientChanges, selectSimulatedWorkerChanges],
  (clientChanges, workerChanges) => {
    return [...clientChanges, ...workerChanges];
  },
);

export const selectChangeById = createSelector(
  [selectAllUnsavedChanges, (_: RootState, key: number | null) => key],
  (rows, key) => (key ? rows[key] : null),
);

export const selectFilteredSimulatedWorkers = createSelector(
  selectSimulatedWorkerChanges,
  selectFocusedWorkerId,
  selectFocusedAppointment,
  selectFilterOptions,
  selectOrderBy,
  selectAllWorkerChanges,
  (rows, focusedWorkerId, focused, filterOptions, orderBy) => {
    if (!filterOptions || Object.values(filterOptions).every((value) => value === null)) {
      if (!orderBy.availability && !orderBy.distance) return rows;
    }

    const filteredRows = rows.filter((row) => {
      const matchesFilters = Object.values(filterOptions)
        .filter((ids) => Array.isArray(ids))
        .every((ids) => ids.includes(row.id));

      return matchesFilters || row.id === focusedWorkerId?.toString();
    });

    if (orderBy.availability) {
      const { date, start_time, end_time } = focused?.appointment || {};
      filteredRows.sort((a, b) => {
        const aAvailable = date
          ? isUserAvailable(
              a.availability?.[dayjs(date).format('dddd') as keyof typeof a.availability] ?? {},
              start_time,
              end_time,
            )
          : false;
        const bAvailable = date
          ? isUserAvailable(
              b.availability?.[dayjs(date).format('dddd') as keyof typeof b.availability] ?? {},
              start_time,
              end_time,
            )
          : false;

        if (aAvailable && !bAvailable) return -1;
        if (!aAvailable && bAvailable) return 1;

        return a.full_name.localeCompare(b.full_name);
      });
    }

    // Ensure that focused worker remains at the top of the sort regardless of filters
    if (focusedWorkerId) {
      filteredRows.sort((a, b) => {
        if (a.id === focusedWorkerId.toString()) return -1;
        if (b.id === focusedWorkerId.toString()) return 1;
        return 0;
      });
    }

    return filteredRows;
  },
);

export const selectInteracting = createSelector(
  selectSimulatedWorkerChanges,
  (state: RootState) => state.weeklyPlanner.unsaved.interacting,
  (rows, key) => {
    if (!key) return null;
    return extractEvent(rows, key) ?? null;
  },
);

export const selectAllLoading = (state: RootState) => {
  return (
    state.weeklyPlanner.unallocated.loading === 'pending' ||
    state.weeklyPlanner.awardAlerts.loading === 'pending' ||
    state.weeklyPlanner.conflicts.loading === 'pending' ||
    state.weeklyPlanner.missingQualifications.loading === 'pending' ||
    state.weeklyPlanner.rejected.loading === 'pending' ||
    state.weeklyPlanner.unsaved.loading.full === 'pending' ||
    state.weeklyPlanner.users.loading === 'pending'
  );
};
