import React, { ReactNode } from 'react';
import { DropdownItem, Label } from 'reactstrap';

import Icon, { faEye, faEyeSlash } from '~components/Icon';

interface ComponentProps {
  iconToggle: boolean;
  onClick: (value?: any) => void;
  label: ReactNode;
  showIcon?: boolean;
}

export const DropDownOption: React.FC<ComponentProps> = ({ iconToggle, label, onClick, showIcon = true }) => (
  <DropdownItem toggle={false} onClick={onClick} className="d-flex align-items-center">
    <Label className="me-2 mb-0">{label}</Label>
    {showIcon && <Icon icon={iconToggle ? faEye : faEyeSlash} className="mt-1 ms-auto" />}
  </DropdownItem>
);

export default DropDownOption;
