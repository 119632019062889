import axios from 'axios';

import { buildSlice, CommonState } from '~libs/reduxUtils';

import { getAll, typePrefix } from '~clients/actions/cataloguePricePlans';

interface CataloguePricePlan {
  id: string;
  name: string;
}

const initialState: CommonState<CataloguePricePlan> = {
  loading: 'idle',
  hasLoaded: false,
  error: null,
  rows: [],
};

const slice = buildSlice<CommonState<CataloguePricePlan>, CataloguePricePlan>(typePrefix, initialState, {
  commonConfig: { getAll },
});

/**
 * TEMPORARY - remove after API integration
 */
const stub: CataloguePricePlan[] = [
  {
    id: '637549d3-bbaf-47e3-a03b-023792ebaad4',
    name: 'Price Plan 1',
  },
  {
    id: '2b25c5d9-44ae-4895-b119-06386ffa4856',
    name: 'Price Plan 2',
  },
  {
    id: 'd903fdc9-a05e-4a55-ad32-51306662cd4c',
    name: 'Price Plan 3',
  },
];

axios.interceptors.response.use((response) => {
  if (response.config.url && response.config.url.match(/\/catalogues\/price-plans(\/|$)/)) {
    if (!response.config.url.includes('/revisions')) {
      response.data = { success: true, message: 'STUB DATA', data: stub };
    }
  }

  return response;
});

// default actions
export const { clear, updateParams } = slice.actions;
export default slice.reducer;
