import { apiCataloguePricePlans } from '~constants/api';

import { get } from '~libs/genericAction';
import { createThunks } from '~libs/reduxUtils';

export const typePrefix = 'catalogue-price-plans';

const config = [{ actionType: 'getAll', apiPath: apiCataloguePricePlans, method: get }];

export const { getAll } = createThunks(typePrefix, config);
