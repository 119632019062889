import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import ReactSelect, { StylesConfig } from 'react-select';

import Button from '~components/Button';
import { InputProps } from '~components/FormFields';
import Icon, { faRefresh } from '~components/Icon';

interface ComponentProps extends InputProps {
  ignorePlaceholder?: boolean;
  styles?: any;
  getOptionStyle?: (option: Option) => React.CSSProperties;
  clearable?: boolean;
}

interface Option {
  value: string | number;
  label: string;
  [key: string]: any;
}

const Select: React.FC<ComponentProps> = ({
  id,
  value,
  placeholder,
  ignorePlaceholder = false,
  className,
  disabled = false,
  options,
  selectorOptions,
  selectorActions,
  selectorParams,
  onChange,
  styles,
  getOptionStyle,
  clearable = true,
  manualReload = false,
  dataCy,
}) => {
  const dispatch = useAppDispatch();
  const [isRefreshing, setIsRefreshing] = useState(false);
  let selectOptions: Option[] = [];
  if (options) {
    selectOptions = options.map(({ id, name, ...props }) => ({ value: id, label: name, ...props }));
  } else if (selectorOptions) {
    const rows = useAppSelector(selectorOptions);
    selectOptions = rows.map(({ id, name, ...props }) => ({ value: id, label: name, ...props }));
  }

  const customStyles: StylesConfig<Option> = {
    option: (provided, state) => ({
      ...provided,
      ...(getOptionStyle ? getOptionStyle(state.data as Option) : {}),
    }),
    ...styles,
  };

  const refreshOptions = async () => {
    if (isRefreshing) return;
    setIsRefreshing(true);

    try {
      if (selectorActions) await dispatch(selectorActions(selectorParams));
    } catch (error: any) {
      console.log(`Error loading options for ${id} : ${error}`);
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <div className="align-items-center row" data-cy={`${id}-select`}>
      <div className="col">
        <ReactSelect
          id={id}
          name={id}
          value={selectOptions.filter((option) => option.value === value)}
          isDisabled={disabled}
          isClearable={clearable}
          options={selectOptions}
          className={className}
          classNamePrefix={'form-input-select'}
          data-cy={dataCy}
          onChange={(option: any) => {
            onChange?.(id, option?.value ?? null);
          }}
          placeholder={!ignorePlaceholder ? (placeholder ?? '-') : undefined}
          styles={customStyles}
        />
      </div>
      {manualReload && (
        <div className="col-sm-1 ms-2">
          <Button size="sm" color="success" onClick={refreshOptions} disabled={isRefreshing}>
            <Icon icon={faRefresh}></Icon>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Select;
