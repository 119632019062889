import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Dropdown, DropdownItem, DropdownMenu } from 'reactstrap';
import classNames from 'classnames';
import dayjs from 'dayjs';

import {
  WeeklyPlannerDateRangeOptions as RangeOptions,
  WeeklyPlannerDatasetOptions as ViewOptions,
} from '~constants/maps';

import { formatDateString, isBeforeDay } from '~libs/dayjs';
import { deriveClientLink } from '~appointments/lib/derive';
import { Appointment, deriveTitleFactory, getTimeFromClick } from '~weekly-planner/lib/common';
import { buildAssignChange } from '~weekly-planner/lib/simulate';

import ContainerPanel from '~components/ContainerPanel';
import { DndWrapper } from '~components/DragAndDrop';
import Icon, { faUpRightFromSquare } from '~components/Icon';
import Link from '~components/Link';
import Banner from '~weekly-planner/components/Banner/Banner';
import ClientCell from '~weekly-planner/components/Main/ClientCell';
import { View as DailyView } from '~weekly-planner/components/Main/Daily';
import { View as WeeklyView } from '~weekly-planner/components/Main/Weekly';
import WorkerCell from '~weekly-planner/components/Main/WorkerCell';
import { SideBar } from '~weekly-planner/components/SideBar';
import { AppointmentWizard } from '~weekly-planner/components/Wizard/AppointmentWizard';

import { create } from '~appointments/actions/appointments';
import { getAll as getCareQualificationLevels } from '~appointments/actions/careQualificationLevels';
import { getAll as getLocations } from '~appointments/actions/locations';
import { getAll as getServiceTypes } from '~appointments/actions/serviceTypes';
import { getAll as getUsers } from '~care-workers/actions';
import { getAll as getDepartments } from '~care-workers/actions/userDepartments';
import { getAll as getPayLevels } from '~care-workers/actions/userPayLevels';
import { getAll as getRegions } from '~main/actions/address/regions';
import { getAll as getClients } from '~main/actions/clients';
import { setTitle } from '~main/actions/login';
import { getExceptions, getOnHold } from '~weekly-planner/actions/appointments';
import { getAll as getConflicts } from '~weekly-planner/actions/appointments/conflicts';
import { getAll as getMissingQualifications } from '~weekly-planner/actions/appointments/missingQualifications';
import { getAll as getRejected } from '~weekly-planner/actions/appointments/rejected';
import { getAll as getUnallocated } from '~weekly-planner/actions/appointments/unallocated';
import { getAwardAlerts, getAwardAlertTypes } from '~weekly-planner/actions/awardAlerts';
import { getAll as getAllClients } from '~weekly-planner/actions/clients';
import { simulate } from '~weekly-planner/actions/unsaved';
import { getAll as getAllWorkers, getRunningLate } from '~weekly-planner/actions/users';

import { toggleQuickMenu } from '~main/reducers/app';
import { clearInteracting, setInteracting } from '~weekly-planner/reducers/unsaved';
import { focusAppointment, openAppointmentDetails, update, updateParams } from '~weekly-planner/reducers/weeklyPlanner';

import { selectFocusedWorkerId, selectParams, selectRangeType, selectViewType } from '~weekly-planner/selectors';
import {
  selectLoading as selectClientsLoading,
  selectHasLoaded as selectHasClientLoaded,
} from '~weekly-planner/selectors/clients';
import {
  selectFilteredSimulatedWorkers,
  selectInteracting,
  selectIsFocusedWorkerInRows,
  selectSimulatedClientChanges,
} from '~weekly-planner/selectors/simulate';
import {
  selectHasLoaded as selectHasUserLoaded,
  selectParams as selectUserParams,
  selectLoading as selectUsersLoading,
} from '~weekly-planner/selectors/users';

dayjs.updateLocale('en', {
  week_start: 1,
});

interface AppointmentDefault {
  date: string | null;
  user_id: number | null;
  start_time: string | undefined;
}
const WeeklyPlanner: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParams);
  const viewType = useAppSelector(selectViewType);
  const rangeType = useAppSelector(selectRangeType);
  const focusedWorkerId = useAppSelector(selectFocusedWorkerId);
  const isWorkerInRows = useAppSelector(selectIsFocusedWorkerInRows);
  const interacting = useAppSelector(selectInteracting);

  const workerData = useAppSelector(selectFilteredSimulatedWorkers);
  const workerDataLoading = useAppSelector(selectUsersLoading);
  const workerDataLoaded = useAppSelector(selectHasUserLoaded);

  const clientData = useAppSelector(selectSimulatedClientChanges);
  const clientDataLoading = useAppSelector(selectClientsLoading);
  const clientDataLoaded = useAppSelector(selectHasClientLoaded);
  const userParams = useAppSelector(selectUserParams);

  const { week_start } = params;

  const [isRightClickMenuOpen, setIsRightClickMenuOpen] = useState(false);
  const [showAppointmentWizard, setShowAppointmentWizard] = useState(false);
  const [appointmentDefaults, setAppointmentDefaults] = useState<AppointmentDefault>({
    date: null,
    user_id: null,
    start_time: undefined,
  });

  const disableUnassign = !interacting?.key || isBeforeDay(dayjs(interacting?.date), dayjs());
  const disableFindNewWorker = !interacting?.id || viewType === ViewOptions.CLIENT;

  const [clickPoint, setClickPoint] = useState({
    x: 0,
    y: 0,
  });

  const getAllSidebar = () => {
    dispatch(getUnallocated({ week_start }));
    dispatch(getExceptions({ ...params, limit: 0 }));
    dispatch(getRunningLate({ ...params, limit: 0 }));
    dispatch(getOnHold({ ...params, limit: 0 }));
    dispatch(getAwardAlerts({ ...params }));
    dispatch(getRejected({ ...params }));
    dispatch(getMissingQualifications({ ...params }));
    dispatch(getConflicts({ ...params }));
  };

  const load = (searchParams?: { [key: string]: any }) => {
    if (week_start) {
      dispatch(clearInteracting());
      getAllSidebar();
      switch (viewType) {
        case ViewOptions.CLIENT: {
          dispatch(getAllClients({ ...params }));
          break;
        }
        case ViewOptions.USER:
        default: {
          dispatch(getAllWorkers({ ...params, ...searchParams, ...userParams }));
          break;
        }
      }
    }
  };

  useEffect(() => {
    setTitle('Weekly Planner');
    // Set quick menu narrow by default for extra screen space
    dispatch(toggleQuickMenu(true));

    dispatch(
      getCareQualificationLevels({
        attributes: ['id', 'name', 'colour'],
      }),
    );

    dispatch(getServiceTypes({ sort: ['name', 'priority'] }));
    dispatch(getUsers({ sort: ['surname'] }));
    dispatch(getAwardAlertTypes());

    // Get search bar based lookups
    dispatch(getRegions());
    dispatch(getDepartments());
    dispatch(getPayLevels());
    dispatch(getClients());
    dispatch(getLocations());

    document.addEventListener('click', leftClickCheck);
    document.addEventListener('contextmenu', contextClick);

    return () => {
      document.removeEventListener('click', leftClickCheck);
      document.removeEventListener('contextmenu', contextClick);
    };
  }, []);

  useEffect(() => {
    load();
  }, [params, viewType, userParams]);

  useEffect(() => {
    getAllSidebar();
    setTitle(`${formatDateString(week_start)} - Weekly Planner `);
  }, [week_start]);

  useEffect(() => {
    if (focusedWorkerId !== null && !isWorkerInRows) {
      dispatch(updateParams({ ...params, focus_worker_id: focusedWorkerId }));
    }
  }, [focusedWorkerId]);

  const contextClick = (event: MouseEvent) => {
    dispatch(clearInteracting());
    dispatch(openAppointmentDetails(null));
    const clickTarget = event.target as HTMLElement;

    const appointmentCard = clickTarget.closest('.appointment-card');
    const dayCard = clickTarget.closest('.day-cell');
    const dailyViewWorkerRow = clickTarget.closest('.worker-row');

    const workerRow = clickTarget.closest('.daily-cell');
    const isValidContextClick = appointmentCard || dayCard || workerRow;

    if (isValidContextClick) {
      event.preventDefault();
      //TODO lock this in with an id to ensure we get the right div
      const mainWindow = document.getElementsByClassName('feature')[0] as HTMLElement;
      const { x, y } = mainWindow.getBoundingClientRect();

      setClickPoint({ x: event.clientX - x, y: event.clientY - y });
    }

    if (appointmentCard) {
      const key = appointmentCard.getAttribute('id')?.split('-')[2];
      dispatch(setInteracting(key));
      setIsRightClickMenuOpen(true);
    }

    let currentDate = dayCard?.getAttribute('data-date') ?? '';
    let user_id = dayCard?.getAttribute('id') ?? '';
    let time;

    if (workerRow) {
      time = getTimeFromClick(workerRow, event);
      currentDate = dailyViewWorkerRow?.getAttribute('data-date') ?? '';
      user_id = workerRow?.getAttribute('id') ?? '';
    }
    setAppointmentDefaults({
      user_id: parseInt(user_id),
      date: currentDate,
      start_time: time?.toString(),
    });

    setIsRightClickMenuOpen(true);

    //context menu should close when we right click off the WP (mimics browser context menu)
    if (!dayCard && !appointmentCard && !workerRow) {
      setIsRightClickMenuOpen(false);
    }
  };

  const leftClickCheck = (event: MouseEvent) => {
    const dropdown = document.getElementById('weekly-planner-context') as HTMLElement;

    if (!dropdown?.contains(event.target as Node)) {
      dispatch(clearInteracting());
      dispatch(openAppointmentDetails(null));
      setIsRightClickMenuOpen(false);
    }
  };

  const { x: left, y: top } = clickPoint;

  const dropdownStyle: CSSProperties = { top, left, position: 'absolute' };

  const MenuLink = ({ to, children, disabled = false }: { to: string; children: ReactNode; disabled?: boolean }) => {
    return (
      <Link to={to} target="_blank" className="text-decoration-none" disabled={disabled}>
        <DropdownItem disabled={disabled}>
          {children}
          <Icon icon={faUpRightFromSquare} className="hover-icon" />
        </DropdownItem>
      </Link>
    );
  };

  const unassignAppointment = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    if (interacting) {
      const change = buildAssignChange(data, [interacting], true);
      dispatch(simulate({ ...change, week_start: dayjs().weekday(0) }));
    }
  };

  const addNewAppointment = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    dispatch(getClients());
    dispatch(getUsers());
    dispatch(
      getServiceTypes({
        deleted: { or: [true, false] },
        sort: ['deleted', 'name'],
      }),
    );
    setShowAppointmentWizard(true);
  };

  const findNewWorker = () => {
    dispatch(update({ date: interacting?.date, rangeType: RangeOptions.DAILY }));
    dispatch(
      focusAppointment({
        appointment: interacting,
        isAvailabilityView: true,
        isBiddingView: false,
      }),
    );
    dispatch(clearInteracting());
  };

  const addAppointment = async (values: any) => {
    const { payload } = await dispatch(create(values));

    if (payload?.success) {
      setShowAppointmentWizard(false);
      setAppointmentDefaults({
        date: null,
        user_id: null,
        start_time: undefined,
      });
      load();
    }

    return payload?.success;
  };

  const isDaily = rangeType === RangeOptions.DAILY;
  const isUser = viewType === ViewOptions.USER;

  const dataLoadingState = isUser ? workerDataLoading : clientDataLoading;

  //Has initially loaded
  const dataHasLoaded = isUser ? workerDataLoaded : clientDataLoaded;

  const data = isUser ? workerData : clientData;
  const headerCell = isUser ? WorkerCell : ClientCell;

  const containerClassName = classNames('main-view', {
    focused: focusedWorkerId !== null,
  });

  const getAppointmentTitle = deriveTitleFactory<Appointment, 'user' | 'client'>(isUser ? 'client' : 'user');

  return (
    <DndWrapper canScroll={!focusedWorkerId}>
      <div className="p-2 weekly-planner">
        <Banner onRefresh={load} loadingState={dataLoadingState} />
        <ContainerPanel className={containerClassName} sidePanel={<SideBar onRefresh={load} />}>
          {!isDaily ? (
            <WeeklyView
              headerCell={headerCell}
              rows={dataHasLoaded ? data : []}
              focusId={focusedWorkerId}
              loadingState={dataLoadingState}
              getAppointmentTitle={getAppointmentTitle}
            />
          ) : (
            <DailyView
              headerCell={headerCell}
              rows={dataHasLoaded ? data : []}
              focusId={focusedWorkerId}
              loadingState={dataLoadingState}
              getAppointmentTitle={getAppointmentTitle}
            />
          )}
        </ContainerPanel>
        <Dropdown
          id="weekly-planner-context"
          style={dropdownStyle}
          isOpen={isRightClickMenuOpen}
          toggle={() => setIsRightClickMenuOpen(!isRightClickMenuOpen)}
          className="weekly-planner-context"
        >
          <DropdownMenu>
            <DropdownItem
              disabled={!interacting?.id}
              onClick={(event) => {
                event.stopPropagation();
                dispatch(openAppointmentDetails(interacting?.id));
                dispatch(clearInteracting());
              }}
            >
              View Details
            </DropdownItem>
            <DropdownItem disabled={disableUnassign} onClick={unassignAppointment}>
              Unassign
            </DropdownItem>
            <DropdownItem disabled={disableFindNewWorker} onClick={findNewWorker}>
              Find New Worker
            </DropdownItem>
            <DropdownItem divider></DropdownItem>
            <DropdownItem onClick={addNewAppointment}>Create Appointment</DropdownItem>
            <DropdownItem divider></DropdownItem>
            <MenuLink disabled={!interacting?.id} to={`${window.origin}/appointments/${interacting?.id}`}>
              View Appointment
            </MenuLink>
            <MenuLink
              disabled={!interacting?.parent_repeat_id}
              to={`${window.origin}/appointments/${interacting?.parent_repeat_id}`}
            >
              View Parent
            </MenuLink>
            <MenuLink disabled={!interacting?.client_id} to={deriveClientLink({ client_id: interacting?.client_id })}>
              View Client
            </MenuLink>
          </DropdownMenu>
        </Dropdown>
        <AppointmentWizard
          showAppointmentWizard={showAppointmentWizard}
          setShowAppointmentWizard={setShowAppointmentWizard}
          appointmentDefaults={appointmentDefaults}
          onAddAppointment={addAppointment}
        />
      </div>
    </DndWrapper>
  );
};

export default WeeklyPlanner;
