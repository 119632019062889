import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import dayjs from 'dayjs';

import { weeklyPlannerDayDisplayFormat } from '~libs/dayjs';

import Icon, { faCircleCheck, faSolidCircleCheck } from '~components/Icon';

import { getAll as getAllNotifications, update as updateNotification } from '~dashboard/actions/notifications';

import { selectAll as selectAllNotifications } from '~dashboard/selectors/notifications';

const Notifications: React.FC = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectAllNotifications);
  const [open, setOpen] = useState<string>('1');

  useEffect(() => {
    dispatch(getAllNotifications());
  }, [dispatch]);

  const markAcknowledged = async (id: number) => {
    await dispatch(updateNotification({ is_acknowledged: true, id }));
    dispatch(getAllNotifications());
  };

  const toggleAccordion = (id: string) => {
    setOpen(open === id ? '' : id);
  };

  const acknowledgedNotifications = notifications.filter(({ is_acknowledged }) => is_acknowledged);
  const newNotifications = notifications.filter(({ is_acknowledged }) => !is_acknowledged);

  return (
    <div className="notifications">
      <Accordion open={open} toggle={() => toggleAccordion('1')}>
        <AccordionItem>
          <AccordionHeader targetId="1" className="new-notifications-header">
            New Notifications
            {newNotifications.length > 0 && <span className="notification-count">({newNotifications.length})</span>}
          </AccordionHeader>
          <AccordionBody accordionId="1" className="new-notifications-body">
            {!(newNotifications.length > 0) && <div className="no-text"> No new notifications...</div>}
            {newNotifications.map(({ id, content, date, time, is_acknowledged }) => (
              <div
                key={id}
                className={`notification ${is_acknowledged ? 'seen' : ''}`}
                onClick={() => markAcknowledged(id as number)}
              >
                <div className="notification-content">
                  <div className="datetime">
                    {dayjs(date).format(weeklyPlannerDayDisplayFormat)} - {time}
                  </div>
                  <div className="message">{content.replace('<br>', ' ')}</div>
                </div>
                <Icon icon={is_acknowledged ? faSolidCircleCheck : faCircleCheck} />
              </div>
            ))}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
      <Accordion open={open} toggle={() => toggleAccordion('2')}>
        <AccordionItem>
          <AccordionHeader targetId="2" className="acknowledged-notifications-header">
            Acknowledged Notifications
          </AccordionHeader>
          <AccordionBody accordionId="2" className="acknowledged-notifications-body">
            {!(acknowledgedNotifications.length > 0) && <div className="no-text"> No notifications...</div>}
            {acknowledgedNotifications.map(({ id, content, date, time, is_acknowledged }) => (
              <div key={id} className={`notification ${is_acknowledged ? 'seen' : ''}`}>
                <div className="notification-content">
                  <div className="datetime">
                    {dayjs(date).format(weeklyPlannerDayDisplayFormat)} - {time}
                  </div>
                  <div className="message">{content.replace('<br>', ' ')}</div>
                </div>
                <Icon icon={is_acknowledged ? faSolidCircleCheck : faCircleCheck} />
              </div>
            ))}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default Notifications;
